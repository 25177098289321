import _process from "process";
var exports = {};
var process = _process;
// Generated by CoffeeScript 1.7.1
(function () {
  var getNanoSeconds, hrtime, loadTime;

  if (typeof performance !== "undefined" && performance !== null && performance.now) {
    exports = function () {
      return performance.now();
    };
  } else if (typeof process !== "undefined" && process !== null && process.hrtime) {
    exports = function () {
      return (getNanoSeconds() - loadTime) / 1000000;
    };

    hrtime = process.hrtime;

    getNanoSeconds = function () {
      var hr;
      hr = hrtime();
      return hr[0] * 1000000000 + hr[1];
    };

    loadTime = getNanoSeconds();
  } else if (Date.now) {
    exports = function () {
      return Date.now() - loadTime;
    };

    loadTime = Date.now();
  } else {
    exports = function () {
      return new Date().getTime() - loadTime;
    };

    loadTime = new Date().getTime();
  }
}).call(exports);
export default exports;